import React from "react";
import { Link } from "gatsby";

import Section from "../Section";
import GitHub from "../../../content/icons/GitHub";
import Twitter from "../../../content/icons/Twitter";
import Facebook from "../../../content/icons/Facebook";
import Instagram from "../../../content/icons/Instagram";
import Linkedin from "../../../content/icons/Linkedin";

const links = [
  {
    name: "Impressum",
    link: "/contact",
  },
  {
    name: "Datenschutz",
    link: "/privacy",
  },
  {
    name: "Unsere Werte",
    link: "/values",
  },
];

const icons = [
  {
    child: <GitHub />,
    href: "https://github.com/F-WuTS",
    label: "robo4you GitHub Page",
  },
  {
    child: <Twitter />,
    href: "https://twitter.com/robo4you",
    label: "robo4you Twitter Page",
  },
  {
    child: <Facebook />,
    href: "https://facebook.com/robo4you",
    label: "robo4you Facebook Page",
  },
  {
    child: <Instagram />,
    href: "https://www.instagram.com/robo4you/",
    label: "robo4you Instagram Page",
  },
  {
    child: <Linkedin />,
    href: "https://at.linkedin.com/company/robo4you",
    label: "robo4you LinkedIn Page",
  },
];

const Footer = () => (
  <footer className="bg-white w-full text-gray-500">
    <Section className="flex flex-col justify-center items-center space-y-6 text-lg">
      <div className="text-gray-500 text-center">
        {links.map((link) => (
          <Link className="px-4" key={link.name} to={link.link}>
            {link.name}
          </Link>
        ))}
      </div>
      <div className="space-x-6 text-gray-500">
        {icons.map((icon) => (
          <a
            className="hover:text-gray-700"
            href={icon.href}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={icon.label}
            key={icon.label}
          >
            {icon.child}
          </a>
        ))}
      </div>
      <div className="text-gray-500">
        <p>
          2024 - Verein zur Förderung von Jugendlichen durch Robotikwettbewerbe
        </p>
      </div>
    </Section>
  </footer>
);

export default Footer;
