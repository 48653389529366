import React from "react";

export default function Section({
  children,
  className,
  background,
  backgroundClassName,
  id,
}) {
  return (
    <div
      id={id}
      className={
        background
          ? backgroundClassName || "bg-background"
          : "bg-background-light"
      }
    >
      <div
        className={`container mx-auto font-sans py-8 lg:py-16 px-8
        ${className}`}
      >
        {children}
      </div>
    </div>
  );
}
