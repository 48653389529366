import React from "react";
import Link from "gatsby-link";
import Logo from "../../../content/images/logo.svg";

const links = [
  // ["/seasons", "Seasons", <span></span>],
  ["/contact", "Kontakt", <span></span>],
  // ["/member", "Member Area", <span></span>],
];

const Navbar = () => {
  return (
    <header>
      <div className="bg-background-nav ">
        <div className="container p-4 mx-auto justify-between flex flex-row">
          {/* Logo */}
          <div className="flex flex-direction-col items-center">
            <Link to={"/"} className="flex items-center">
              <Logo className="h-12 w-auto" />
            </Link>
          </div>

          {/* Desktop Menu */}
          <nav className="hidden lg:flex items-center space-x-4">
            {links.map((link, index) => {
              return (
                <Link
                  key={index}
                  to={link[0]}
                  className="px-3 py-2 text-xl font-semibold rounded flex items-center text-gradient bg-gradient-to-r hover:from-blue-500 hover:to-indigo-600 from-background-text to-background-text transition-colors"
                >
                  <span>{link[1]}</span>
                  {link[2]}
                </Link>
              );
            })}
          </nav>

          {/* Mobile Menu */}
          <nav className="flex items-center block lg:hidden">
            <style>
              {`
            #burger:checked ~ #mobileMenu {
              max-height: 100vh;
              visibility: visible;
              opacity: 1
            }`}
            </style>
            <input
              type="checkbox"
              id="burger"
              className="absolute w-6 opacity-0 cursor-pointer checked:opacity-1"
            />
            <svg
              className="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>

            <div
              className="absolute left-0 right-0 top-20 z-10 bg-white transition-all duration-200 invisible opacity-0 max-h-0"
              id="mobileMenu"
            >
              <div className="container mx-auto px-6 pb-4 flex flex-col">
                {links.map((link, index) => {
                  return link[0] ? (
                    <Link
                      key={index}
                      to={link[0]}
                      className="py-2 text-md rounded"
                    >
                      <span>{link[1]}</span>
                      {link[2]}
                    </Link>
                  ) : (
                    <div key={index} className="rounded h-px w-24 bg-primary" />
                  );
                })}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
