import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Section from "../components/Section";

const Season = ({ data }) => {
  return (
    <div>
      <Layout>
        <Section className="max-w-screen-md">
          <h1>Season: {data.season.frontmatter.title}</h1>
          <div
            className="prose"
            dangerouslySetInnerHTML={{ __html: data.season.html }}
          />
        </Section>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    season: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
`;

export default Season;
