import React from "react";

import Footer from "../Footer";
import Navbar from "../Navbar";

const Layout = ({ children }) => (
  <div>
    <Navbar />
    <div className="min-h-full xl:min-h-full-no-nav-footer">{children}</div>
    <Footer />
  </div>
);

export default Layout;
